import { usePreferredLocale } from '@sprinx/react-globalize';
import { AdminStaticContentDto, AdminStaticPageListDto } from './api/adminContent';
import { Manufacturer } from './api/products/productManufacturers';

export class AdminConfig {
  private static get root() {
    return (typeof window !== 'undefined' && window) || global;
  }
  public static get manufacturers(): Manufacturer[] | undefined {
    return this.root['manufacturers'];
  }
  static get mainInfoBox(): AdminStaticContentDto[] | undefined {
    return this.root['mainInfoBox'];
  }
  static get secondaryInfoBox(): AdminStaticContentDto[] | undefined {
    return this.root['secondaryInfoBox'];
  }
  static get imageSection(): AdminStaticContentDto[] | undefined {
    return this.root['imageSection'];
  }
  static get leftImageUnderCarousel(): AdminStaticContentDto[] | undefined {
    return this.root['leftImageUnderCarousel'];
  }

  static get rightImageUnderCarousel(): AdminStaticContentDto[] | undefined {
    return this.root['rightImageUnderCarousel'];
  }
  static get homepageMainImage(): AdminStaticContentDto[] | undefined {
    return this.root['homepageMainImage'];
  }
  static get carousel(): AdminStaticContentDto[] | undefined {
    return this.root['carousel'];
  }
  static get footerContactBox(): AdminStaticContentDto[] | undefined {
    return this.root['footerContactBox'];
  }
  static get footerHeadquarters(): AdminStaticContentDto[] | undefined {
    return this.root['footerHeadquarters'];
  }
  static get documentToDownload(): AdminStaticContentDto[] | undefined {
    return this.root['documentToDownload'];
  }
  static get staticPageList(): AdminStaticPageListDto | undefined {
    return this.root['staticPageList'];
  }
}

const getOneVisibleForLocale = (items: AdminStaticContentDto[] | undefined, locale: string) => {
  if (!items) return;

  return items?.filter((i) => !i.hidden)?.find((i) => i.locale === locale);
};

const getVisibileForLocale = (items: AdminStaticContentDto[] | undefined, locale: string) => {
  if (!items) return;
  return items?.filter((i) => !i.hidden && i.locale === locale);
};

export const useAdminConfig = () => {
  const locale = usePreferredLocale();

  return {
    manufacturers: AdminConfig.manufacturers,
    mainInfoBox: getOneVisibleForLocale(AdminConfig.mainInfoBox, locale),
    secondaryInfoBox: getOneVisibleForLocale(AdminConfig.secondaryInfoBox, locale),
    leftImageUnderCarousel: getOneVisibleForLocale(AdminConfig.leftImageUnderCarousel, locale),
    rightImageUnderCarousel: getOneVisibleForLocale(AdminConfig.rightImageUnderCarousel, locale),
    homepageMainImage: getOneVisibleForLocale(AdminConfig.homepageMainImage, locale),
    carousel: getVisibileForLocale(AdminConfig.carousel, locale),
    footerContactBox: getOneVisibleForLocale(AdminConfig.footerContactBox, locale),
    footerHeadquarters: getOneVisibleForLocale(AdminConfig.footerHeadquarters, locale),
    documentToDownload: getVisibileForLocale(AdminConfig.documentToDownload, locale),
    staticPageList: AdminConfig.staticPageList?.items[locale],
  };
};

export const FTP_URL = 'https://pht.sxcdn.net';
