import deepOrange from '@material-ui/core/colors/deepOrange';
import { ThemeOptions } from '@material-ui/core';
import { ApplicationSettings } from './@sprinx/react-after-razzle';
import { SupportedLocale } from './i18n/types';
import { RouteUrlType } from './routesBuilder';

type StringRecord = Partial<Record<SupportedLocale, string>> & { default: string };

export type DocumentFile = {
  alt: StringRecord;
  href?: string;
  hrefProps?: {
    options?: {
      otherParamsToQs?: boolean | string[];
      params?: Record<string, string | string[]>;
    };
    type: RouteUrlType;
  };
  src: StringRecord;
  title: StringRecord;
};

type Link = {
  href: string;
  label: StringRecord;
};

export type DocumentFileNames = 'bulkImportDocument' | 'homepageMainImage';

export type MultipleDocumentFileNames = 'downloadedDocuments' | 'homepageCarousel' | 'homepageImageSection';

type StaticContentTypeKebabCase =
  | 'homepage-main-info-box'
  | 'homepage-secondary-info-box'
  | 'business-conditions'
  | 'gdpr'
  | 'footer-contact-box'
  | 'footer-headquarters';

type CustomerProfileNavigationItem =
  | 'customerProfile'
  | 'customerProfileOrders'
  | 'customerProfileInvoices'
  | 'customerProfileCreditNotes'
  | 'customerProfileInvoicesAndCreditNotes'
  | 'customerProfileDocuments'
  | 'customerProfileDeliveryNotes'
  | 'customerProfileShoppingLists'
  | 'cartsApproval';

type ModuleConfig = {
  activeManufacturersFilter: boolean;
  customerProfileNavigationItems: CustomerProfileNavigationItem[];
  customNavigationLinks: Link[] | null;
  files: Record<DocumentFileNames, DocumentFile | null>;
  monitorStockAvailability: boolean;
  multipleFiles: Record<MultipleDocumentFileNames, DocumentFile[] | null>;
  productDetailAuthenticatedParameterCodes: string[];
  productDetailGlobalParameters: string[] | null;
  showContactPage: boolean;
  showDownloadDocuments: boolean;
  showExternalDocumentsDetail: boolean;
  showGdpr: boolean;
  showProductDetailSpagettyNavigation: boolean;
  showStockAvailability: boolean;
  showTermsAndConditions: boolean;
  staticContentTypes: StaticContentTypeKebabCase[];
  themeOptions: ThemeOptions;
} & Pick<ApplicationSettings, 'supportedCurrencies' | 'supportedLocales' | 'pricesType'>;

type EnvConfig = {
  projectName: string;
  projectNameUpperCase: string;
  rumApiKey: string | undefined;
} & Pick<ApplicationSettings, 'currency' | 'locale' | 'region' | 'anonymouseUserId'>;

type Config = ApplicationSettings & EnvConfig & ModuleConfig;

const allConfigs: Record<string, ModuleConfig> = {
  mapo: {
    activeManufacturersFilter: false,
    productDetailAuthenticatedParameterCodes: [],
    productDetailGlobalParameters: null,
    showProductDetailSpagettyNavigation: false,
    customerProfileNavigationItems: [
      'customerProfile',
      'customerProfileOrders',
      'customerProfileInvoices',
      'customerProfileCreditNotes',
      'customerProfileDeliveryNotes',
      'customerProfileShoppingLists',
      'cartsApproval',
    ],
    showExternalDocumentsDetail: false,
    supportedCurrencies: ['CZK'],

    supportedLocales: ['cs'],
    pricesType: 'B2B',

    customNavigationLinks: null,
    monitorStockAvailability: false,
    showStockAvailability: false,
    showContactPage: true,
    showTermsAndConditions: true,
    showDownloadDocuments: false,
    showGdpr: true,
    themeOptions: {
      palette: {
        appBar: {
          background: '#F4F4F4',
          color: {
            contrastText: '#000000',
            logo: '#000000',
          },
        },
        mainNavigation: {
          background: '#F4F4F4',
          backgroundActive: '#000000',
        },
        mainNavigationContent: {
          background: '#F4F4F4',
          border: '1px solid',
        },
        appFooter: {
          color: '#046FBF',
          background: '#F4F4F4',
        },
      },
    },
    files: {
      bulkImportDocument: {
        src: {
          en: '/bulkImportDocuments/bulk_import_en.csv',
          cs: '/bulkImportDocuments/hromadny_import_cz.csv',
          default: '/bulkImportDocuments/bulk_import_en.csv',
        },
        alt: {
          en: 'Bulk import template',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
        title: {
          en: 'Bulk import template',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
      },
      homepageMainImage: null,
    },
    multipleFiles: {
      downloadedDocuments: null,
      homepageCarousel: [
        {
          src: {
            en: '/homepage/banner_karusel_01.jpg',
            cs: '/homepage/banner_karusel_01.jpg',
            default: '/homepage/banner_karusel_01.jpg',
          },
          alt: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          title: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'med-comfort-high-risk-rukavice', id: '6603e1e479b33d001b587ec8' },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner_karusel_02.jpg',
            cs: '/homepage/banner_karusel_02.jpg',
            default: '/homepage/banner_karusel_02.jpg',
          },
          alt: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          title: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a3' } },
          },
        },
        {
          src: {
            en: '/homepage/banner_karusel_03.jpg',
            cs: '/homepage/banner_karusel_03.jpg',
            default: '/homepage/banner_karusel_03.jpg',
          },
          alt: {
            en: 'Banner - karusel 3',
            cs: 'Banner - karusel 3',
            default: 'Banner - karusel 3',
          },
          title: {
            en: 'Banner - karusel 3',
            cs: 'Banner - karusel 3',
            default: 'Banner - karusel 3',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a4' } },
          },
        },
      ],
      homepageImageSection: [
        {
          src: {
            en: '/homepage/banner_akce.jpg',
            cs: '/homepage/banner_akce.jpg',
            default: '/homepage/banner_akce.jpg',
          },
          alt: {
            en: 'Info banner - Action',
            cs: 'Info banner - Akce',
            default: 'Info banner - Action',
          },
          title: {
            en: 'Info banner - Action',
            cs: 'Info banner - Akce',
            default: 'Info banner - Action',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a1' } },
          },
        },
        {
          src: {
            en: '/homepage/banner_vyprodej.jpg',
            cs: '/homepage/banner_vyprodej.jpg',
            default: '/homepage/banner_vyprodej.jpg',
          },
          alt: {
            en: 'Info banner - warehouse stock sale',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          title: {
            en: 'Info banner - warehouse stock sale',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a2' } },
          },
        },
      ],
    },
    staticContentTypes: [
      'homepage-main-info-box',
      'homepage-secondary-info-box',
      'business-conditions',
      'gdpr',
      'footer-contact-box',
      'footer-headquarters',
    ],
  },
  pht: {
    activeManufacturersFilter: true,
    productDetailAuthenticatedParameterCodes: ['/commonParams/minimumOrderQuantity'],
    productDetailGlobalParameters: null,
    showProductDetailSpagettyNavigation: false,
    customerProfileNavigationItems: [
      'customerProfile',
      'customerProfileOrders',
      'customerProfileInvoicesAndCreditNotes',
      'customerProfileDeliveryNotes',
      'customerProfileShoppingLists',
      'cartsApproval',
    ],
    showExternalDocumentsDetail: false,
    supportedCurrencies: ['CZK', 'EUR'],
    supportedLocales: ['cs', 'en', 'de', 'hu', 'sk', 'hr', 'sl'],
    pricesType: 'B2B',
    customNavigationLinks: [],
    monitorStockAvailability: true,
    showStockAvailability: true,
    showContactPage: true,
    showTermsAndConditions: true,
    showDownloadDocuments: true,
    showGdpr: true,
    themeOptions: {
      palette: {
        primary: {
          light: '#042751',
          main: '#042751',
          dark: '#282F49',
          contrastText: '#ffffff',
        },
        secondary: deepOrange,
        appBar: {
          background: '#F7D417',
          color: {
            contrastText: '#000000',
            logo: '#000000',
          },
        },
        mainNavigation: {
          background: '#B3B3B3',
          backgroundActive: '#000000',
        },
        mainNavigationContent: {
          background: '#F7D417',
        },
        background: {
          default: '#FFFFFF',
        },
        appFooter: {
          background: '#002969',
          color: '#FFFFFF',
        },
      },
    },
    files: {
      bulkImportDocument: {
        src: {
          en: '/bulkImportDocuments/bulk_import_en.csv',
          de: '/bulkImportDocuments/bulk_import_en.csv',
          hu: '/bulkImportDocuments/bulk_import_en.csv',
          hr: '/bulkImportDocuments/bulk_import_en.csv',
          sl: '/bulkImportDocuments/bulk_import_en.csv',
          sk: '/bulkImportDocuments/hromadny_import_cz.csv',
          cs: '/bulkImportDocuments/hromadny_import_cz.csv',
          default: '/bulkImportDocuments/bulk_import_en.csv',
        },
        alt: {
          en: 'Bulk import template',
          de: 'Bulk import template',
          hu: 'Bulk import template',
          hr: 'Bulk import template',
          sl: 'Bulk import template',
          sk: 'Šablona pro hromadný import',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
        title: {
          en: 'Bulk import template',
          de: 'Bulk import template',
          hu: 'Bulk import template',
          hr: 'Bulk import template',
          sl: 'Bulk import template',
          sk: 'Šablona pro hromadný import',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
      },
      homepageMainImage: {
        src: {
          en: '/homepage/banner ADBLUE EN.jpg',
          de: '/homepage/banner ADBLUE EN.jpg',
          hu: '/homepage/banner ADBLUE EN.jpg',
          hr: '/homepage/banner ADBLUE EN.jpg',
          sl: '/homepage/banner ADBLUE EN.jpg',
          sk: '/homepage/banner ADBLUE CZ.jpg',
          cs: '/homepage/banner ADBLUE CZ.jpg',
          default: '/homepage/banner ADBLUE EN.jpg',
        },
        alt: {
          en: 'Banner ADBLUE EN',
          de: 'Banner ADBLUE EN',
          hu: 'Banner ADBLUE EN',
          hr: 'Banner ADBLUE EN',
          sl: 'Banner ADBLUE EN',
          sk: 'Banner ADBLUE CZ',
          cs: 'Banner ADBLUE CZ',
          default: 'Banner ADBLUE EN',
        },
        title: {
          en: 'Banner ADBLUE EN',
          de: 'Banner ADBLUE EN',
          hu: 'Banner ADBLUE EN',
          hr: 'Banner ADBLUE EN',
          sl: 'Banner ADBLUE EN',
          sk: 'Banner ADBLUE CZ',
          cs: 'Banner ADBLUE CZ',
          default: 'Banner ADBLUE EN',
        },
        hrefProps: {
          type: 'categories',
          options: { params: { categories: ['07-dilna-a-garaz', 'autodoplnky', 'ostatni'] } },
        },
      },
    },
    multipleFiles: {
      downloadedDocuments: [
        {
          src: {
            en: '/documents/Letak_zari_2024.pdf',
            de: '/documents/Letak_zari_2024.pdf',
            hu: '/documents/Letak_zari_2024.pdf',
            hr: '/documents/Letak_zari_2024.pdf',
            sl: '/documents/Letak_zari_2024.pdf',
            sk: '/documents/Letak_zari_2024.pdf',
            cs: '/documents/Letak_zari_2024.pdf',
            default: '/documents/Letak_zari_2024.pdf',
          },
          alt: {
            en: 'Leaflet September 2024',
            de: 'Leaflet September 2024',
            hu: 'Leaflet September 2024',
            hr: 'Leaflet September 2024',
            sl: 'Leaflet September 2024',
            sk: 'Leták září 2024',
            cs: 'Leták září 2024',
            default: 'Leaflet September 2024',
          },
          title: {
            en: 'Leaflet September 2024',
            de: 'Leaflet September 2024',
            hu: 'Leaflet September 2024',
            hr: 'Leaflet September 2024',
            sl: 'Leaflet September 2024',
            sk: 'Leták září 2024',
            cs: 'Leták září 2024',
            default: 'Leaflet September 2024',
          },
        },
        {
          src: {
            en: '/documents/PHT_cenik_092024.xlsx',
            de: '/documents/PHT_cenik_092024.xlsx',
            hu: '/documents/PHT_cenik_092024.xlsx',
            hr: '/documents/PHT_cenik_092024.xlsx',
            sl: '/documents/PHT_cenik_092024.xlsx',
            sk: '/documents/PHT_cenik_092024.xlsx',
            cs: '/documents/PHT_cenik_092024.xlsx',
            default: '/documents/PHT_cenik_092024.xlsx',
          },
          alt: {
            en: 'PHT price list 09-2024',
            de: 'PHT price list 09-2024',
            hu: 'PHT price list 09-2024',
            hr: 'PHT price list 09-2024',
            sl: 'PHT price list 09-2024',
            sk: 'PHT ceník 09-2024',
            cs: 'PHT ceník 09-2024',
            default: 'PHT price list 09-2024',
          },
          title: {
            en: 'PHT price list 09-2024',
            de: 'PHT price list 09-2024',
            hu: 'PHT price list 09-2024',
            hr: 'PHT price list 09-2024',
            sl: 'PHT price list 09-2024',
            sk: 'PHT ceník 09-2024',
            cs: 'PHT ceník 09-2024',
            default: 'PHT price list 09-2024',
          },
        },
        {
          src: {
            en: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            de: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            hu: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            hr: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            sl: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            sk: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            cs: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
            default: '/documents/202409_PHT_a.s._změna_ceny.xlsx',
          },
          alt: {
            en: 'PHT a.s. price adjustments',
            de: 'PHT a.s. price adjustments',
            hu: 'PHT a.s. price adjustments',
            hr: 'PHT a.s. price adjustments',
            sl: 'PHT a.s. price adjustments',
            sk: 'PHT a.s. změny cen',
            cs: 'PHT a.s. změny cen',
            default: 'PHT a.s. price adjustments',
          },
          title: {
            en: 'PHT a.s. price adjustments',
            de: 'PHT a.s. price adjustments',
            hu: 'PHT a.s. price adjustments',
            hr: 'PHT a.s. price adjustments',
            sl: 'PHT a.s. price adjustments',
            sk: 'PHT a.s. změny cen',
            cs: 'PHT a.s. změny cen',
            default: 'PHT a.s. price adjustments',
          },
        },
      ],
      homepageCarousel: [
        {
          src: {
            en: '/homepage/banner_POLY04_EN.jpg',
            de: '/homepage/banner_POLY04_EN.jpg',
            hu: '/homepage/banner_POLY04_EN.jpg',
            hr: '/homepage/banner_POLY04_EN.jpg',
            sl: '/homepage/banner_POLY04_EN.jpg',
            sk: '/homepage/banner_POLY04_CZ.jpg',
            cs: '/homepage/banner_POLY04_CZ.jpg',
            default: '/homepage/banner_POLY04_EN.jpg',
          },
          alt: {
            en: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            de: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            hu: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            hr: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            sl: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            sk: 'POLY04 - Tuson polyfuzni svarecka 350 w aku 18 v makita bez baterie',
            cs: 'POLY04 - Tuson polyfuzni svarecka 350 w aku 18 v makita bez baterie',
            default: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
          },
          title: {
            en: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            de: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            hu: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            hr: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            sl: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
            sk: 'POLY04 - Tuson polyfuzni svarecka 350 w aku 18 v makita bez baterie',
            cs: 'POLY04 - Tuson polyfuzni svarecka 350 w aku 18 v makita bez baterie',
            default: 'POLY04 - TUSON - Polyfusion welding machine 350W AKU 18V (Makita; without battery)',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: {
                sn: 'tuson-polyfuzni-svarecka-350-w-aku-18-v-makita-bez-baterie',
                id: '66bb140f41ac4d001cc231bb',
              },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner_2305-4_EN.jpg',
            de: '/homepage/banner_2305-4_EN.jpg',
            hu: '/homepage/banner_2305-4_EN.jpg',
            hr: '/homepage/banner_2305-4_EN.jpg',
            sl: '/homepage/banner_2305-4_EN.jpg',
            sk: '/homepage/banner_2305-4_CZ.jpg',
            cs: '/homepage/banner_2305-4_CZ.jpg',
            default: '/homepage/banner_2305-4_EN.jpg',
          },
          alt: {
            en: '2305-4 - MEVA - table cooker Rivet - low pressure',
            de: '2305-4 - MEVA - table cooker Rivet - low pressure',
            hu: '2305-4 - MEVA - table cooker Rivet - low pressure',
            hr: '2305-4 - MEVA - table cooker Rivet - low pressure',
            sl: '2305-4 - MEVA - table cooker Rivet - low pressure',
            sk: '2305-4 - MEVA - vařič stolní RIVET - nízkotlaký',
            cs: '2305-4 - MEVA - vařič stolní RIVET - nízkotlaký',
            default: '2305-4 - MEVA - table cooker Rivet - low pressure',
          },
          title: {
            en: '2305-4 - MEVA - table cooker Rivet - low pressure',
            de: '2305-4 - MEVA - table cooker Rivet - low pressure',
            hu: '2305-4 - MEVA - table cooker Rivet - low pressure',
            hr: '2305-4 - MEVA - table cooker Rivet - low pressure',
            sl: '2305-4 - MEVA - table cooker Rivet - low pressure',
            sk: '2305-4 - MEVA - vařič stolní RIVET - nízkotlaký',
            cs: '2305-4 - MEVA - vařič stolní RIVET - nízkotlaký',
            default: '2305-4 - MEVA - table cooker Rivet - low pressure',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'meva-varic-stolni-rivet-nizkotlaky', id: '6690c7ee41ac4d001cc231a3' },
            },
          },
        },
        {
          src: {
            en: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
            de: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
            hu: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
            hr: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
            sl: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
            sk: '/homepage/Banner_Momentove_klice_-_CZ.jpeg',
            cs: '/homepage/Banner_Momentove_klice_-_CZ.jpeg',
            default: '/homepage/Banner_Momentove_klice_-_EN.jpeg',
          },
          alt: {
            en: 'Banner Momentove klice',
            de: 'Banner Momentove klice',
            hu: 'Banner Momentove klice',
            hr: 'Banner Momentove klice',
            sl: 'Banner Momentove klice',
            sk: 'Banner Momentove klice',
            cs: 'Banner Momentove klice',
            default: 'Banner Momentove klice',
          },
          title: {
            en: 'Banner Momentove klice',
            de: 'Banner Momentove klice',
            hu: 'Banner Momentove klice',
            hr: 'Banner Momentove klice',
            sl: 'Banner Momentove klice',
            sk: 'Banner Momentove klice',
            cs: 'Banner Momentove klice',
            default: 'Banner Momentove klice',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/banner_080176A080177EN.jpg',
            de: '/homepage/banner_080176A080177EN.jpg',
            hu: '/homepage/banner_080176A080177EN.jpg',
            hr: '/homepage/banner_080176A080177EN.jpg',
            sl: '/homepage/banner_080176A080177EN.jpg',
            sk: '/homepage/banner_080176A080177cz.jpg',
            cs: '/homepage/banner_080176A080177cz.jpg',
            default: '/homepage/banner_080176A080177EN.jpg',
          },
          alt: {
            en: 'Banner replacement jaws',
            de: 'Banner replacement jaws',
            hu: 'Banner replacement jaws',
            hr: 'Banner replacement jaws',
            sl: 'Banner replacement jaws',
            sk: 'Banner náhradní čelisti',
            cs: 'Banner náhradní čelisti',
            default: 'Banner replacement jaws',
          },
          title: {
            en: 'Banner replacement jaws',
            de: 'Banner replacement jaws',
            hu: 'Banner replacement jaws',
            hr: 'Banner replacement jaws',
            sl: 'Banner replacement jaws',
            sk: 'Banner náhradní čelisti',
            cs: 'Banner náhradní čelisti',
            default: 'Banner replacement jaws',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/Banner_SVS..._-_EN.jpg',
            de: '/homepage/Banner_SVS..._-_EN.jpg',
            hu: '/homepage/Banner_SVS..._-_EN.jpg',
            hr: '/homepage/Banner_SVS..._-_EN.jpg',
            sl: '/homepage/Banner_SVS..._-_EN.jpg',
            sk: '/homepage/Banner_SVS..._-_CZ.jpg',
            cs: '/homepage/Banner_SVS..._-_CZ.jpg',
            default: '/homepage/Banner_SVS..._-_EN.jpg',
          },
          alt: {
            en: 'Banner_SVS...',
            de: 'Banner_SVS...',
            hu: 'Banner_SVS...',
            hr: 'Banner_SVS...',
            sl: 'Banner_SVS...',
            sk: 'Banner_SVS...',
            cs: 'Banner_SVS...',
            default: 'Banner_SVS...',
          },
          title: {
            en: 'Banner_SVS...',
            de: 'Banner_SVS...',
            hu: 'Banner_SVS...',
            hr: 'Banner_SVS...',
            sl: 'Banner_SVS...',
            sk: 'Banner_SVS...',
            cs: 'Banner_SVS...',
            default: 'Banner_SVS...',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/Banner_040018_EN.jpg',
            de: '/homepage/Banner_040018_EN.jpg',
            hu: '/homepage/Banner_040018_EN.jpg',
            hr: '/homepage/Banner_040018_EN.jpg',
            sl: '/homepage/Banner_040018_EN.jpg',
            sk: '/homepage/Banner_040018_CZ.jpg',
            cs: '/homepage/Banner_040018_CZ.jpg',
            default: '/homepage/Banner_040018_EN.jpg',
          },
          alt: {
            en: 'Brush for brush cutter - nylon',
            de: 'Brush for brush cutter - nylon',
            hu: 'Brush for brush cutter - nylon',
            hr: 'Brush for brush cutter - nylon',
            sl: 'Brush for brush cutter - nylon',
            sk: 'Kartáč pro křovinořez - nylonový',
            cs: 'Kartáč pro křovinořez - nylonový',
            default: 'Brush for brush cutter - nylon',
          },
          title: {
            en: 'Brush for brush cutter - nylon',
            de: 'Brush for brush cutter - nylon',
            hu: 'Brush for brush cutter - nylon',
            hr: 'Brush for brush cutter - nylon',
            sl: 'Brush for brush cutter - nylon',
            sk: 'Kartáč pro křovinořez - nylonový',
            cs: 'Kartáč pro křovinořez - nylonový',
            default: 'Brush for brush cutter - nylon',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'brush-for-brush-cutter-nylon', id: '666aeedb41ac4d001cc23107' },
            },
          },
        },
        {
          src: {
            en: '/homepage/Banner_ZT-320_EN.jpg',
            de: '/homepage/Banner_ZT-320_EN.jpg',
            hu: '/homepage/Banner_ZT-320_EN.jpg',
            hr: '/homepage/Banner_ZT-320_EN.jpg',
            sl: '/homepage/Banner_ZT-320_EN.jpg',
            sk: '/homepage/Banner_ZT-320_CZ.jpg',
            cs: '/homepage/Banner_ZT-320_CZ.jpg',
            default: '/homepage/Banner_ZT-320_EN.jpg',
          },
          alt: {
            en: 'MAGG Profi - Telescopic ladder 3.2 m',
            de: 'MAGG Profi - Telescopic ladder 3.2 m',
            hu: 'MAGG Profi - Telescopic ladder 3.2 m',
            hr: 'MAGG Profi - Telescopic ladder 3.2 m',
            sl: 'MAGG Profi - Telescopic ladder 3.2 m',
            sk: 'MAGG Profi - Žebřík teleskopický 3,2 m',
            cs: 'MAGG Profi - Žebřík teleskopický 3,2 m',
            default: 'MAGG Profi - Telescopic ladder 3.2 m',
          },
          title: {
            en: 'MAGG Profi - Telescopic ladder 3.2 m',
            de: 'MAGG Profi - Telescopic ladder 3.2 m',
            hu: 'MAGG Profi - Telescopic ladder 3.2 m',
            hr: 'MAGG Profi - Telescopic ladder 3.2 m',
            sl: 'MAGG Profi - Telescopic ladder 3.2 m',
            sk: 'MAGG Profi - Žebřík teleskopický 3,2 m',
            cs: 'MAGG Profi - Žebřík teleskopický 3,2 m',
            default: 'MAGG Profi - Telescopic ladder 3.2 m',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'profi-zebrik-teleskopicky-3-2-m', id: '665dcdec41ac4d001cc230ff' },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner 120287 EN.jpg',
            de: '/homepage/banner 120287 EN.jpg',
            hu: '/homepage/banner 120287 EN.jpg',
            hr: '/homepage/banner 120287 EN.jpg',
            sl: '/homepage/banner 120287 EN.jpg',
            sk: '/homepage/banner 120287 CZ.jpg',
            cs: '/homepage/banner 120287 CZ.jpg',
            default: '/homepage/banner 120287 EN.jpg',
          },
          alt: {
            en: 'Banner 120287 EN',
            de: 'Banner 120287 EN',
            hu: 'Banner 120287 EN',
            hr: 'Banner 120287 EN',
            sl: 'Banner 120287 EN',
            sk: 'Banner 120287 CZ',
            cs: 'Banner 120287 CZ',
            default: 'Banner 120287 EN',
          },
          title: {
            en: 'Banner 120287 EN',
            de: 'Banner 120287 EN',
            hu: 'Banner 120287 EN',
            hr: 'Banner 120287 EN',
            sl: 'Banner 120287 EN',
            sk: 'Banner 120287 CZ',
            cs: 'Banner 120287 CZ',
            default: 'Banner 120287 EN',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'magg-profi-koza-skladaci-stavitelna', id: '6683b50d41ac4d001cc2314a' },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner 080185 EN.jpg',
            de: '/homepage/banner 080185 EN.jpg',
            hu: '/homepage/banner 080185 EN.jpg',
            hr: '/homepage/banner 080185 EN.jpg',
            sl: '/homepage/banner 080185 EN.jpg',
            sk: '/homepage/banner 080185 CZ.jpg',
            cs: '/homepage/banner 080185 CZ.jpg',
            default: '/homepage/banner 080185 EN.jpg',
          },
          alt: {
            en: 'Banner 080185 EN',
            de: 'Banner 080185 EN',
            hu: 'Banner 080185 EN',
            hr: 'Banner 080185 EN',
            sl: 'Banner 080185 EN',
            sk: 'Banner 080185 CZ',
            cs: 'Banner 080185 CZ',
            default: 'Banner 080185 EN',
          },
          title: {
            en: 'Banner 080185 EN',
            de: 'Banner 080185 EN',
            hu: 'Banner 080185 EN',
            hr: 'Banner 080185 EN',
            sl: 'Banner 080185 EN',
            sk: 'Banner 080185 CZ',
            cs: 'Banner 080185 CZ',
            default: 'Banner 080185 EN',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/banner 080181 EN.jpg',
            de: '/homepage/banner 080181 EN.jpg',
            hu: '/homepage/banner 080181 EN.jpg',
            hr: '/homepage/banner 080181 EN.jpg',
            sl: '/homepage/banner 080181 EN.jpg',
            sk: '/homepage/banner 080181 CZ.jpg',
            cs: '/homepage/banner 080181 CZ.jpg',
            default: '/homepage/banner 080181 EN.jpg',
          },
          alt: {
            en: 'Banner 080181 EN',
            de: 'Banner 080181 EN',
            hu: 'Banner 080181 EN',
            hr: 'Banner 080181 EN',
            sl: 'Banner 080181 EN',
            sk: 'Banner 080181 CZ',
            cs: 'Banner 080181 CZ',
            default: 'Banner 080181 EN',
          },
          title: {
            en: 'Banner 080181 EN',
            de: 'Banner 080181 EN',
            hu: 'Banner 080181 EN',
            hr: 'Banner 080181 EN',
            sl: 'Banner 080181 EN',
            sk: 'Banner 080181 CZ',
            cs: 'Banner 080181 CZ',
            default: 'Banner 080181 EN',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/banner 120284 EN.jpg',
            de: '/homepage/banner 120284 EN.jpg',
            hu: '/homepage/banner 120284 EN.jpg',
            hr: '/homepage/banner 120284 EN.jpg',
            sl: '/homepage/banner 120284 EN.jpg',
            sk: '/homepage/banner 120284 CZ.jpg',
            cs: '/homepage/banner 120284 CZ.jpg',
            default: '/homepage/banner 120284 EN.jpg',
          },
          alt: {
            en: 'Banner 120284 EN',
            de: 'Banner 120284 EN',
            hu: 'Banner 120284 EN',
            hr: 'Banner 120284 EN',
            sl: 'Banner 120284 EN',
            sk: 'Banner 120284 CZ',
            cs: 'Banner 120284 CZ',
            default: 'Banner 120284 EN',
          },
          title: {
            en: 'Banner 120284 EN',
            de: 'Banner 120284 EN',
            hu: 'Banner 120284 EN',
            hr: 'Banner 120284 EN',
            sl: 'Banner 120284 EN',
            sk: 'Banner 120284 CZ',
            cs: 'Banner 120284 CZ',
            default: 'Banner 120284 EN',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'magneticka-miska-skladaci', id: '6683b50d41ac4d001cc23147' },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner 120285 EN.jpg',
            de: '/homepage/banner 120285 EN.jpg',
            hu: '/homepage/banner 120285 EN.jpg',
            hr: '/homepage/banner 120285 EN.jpg',
            sl: '/homepage/banner 120285 EN.jpg',
            sk: '/homepage/banner 120285 CZ.jpg',
            cs: '/homepage/banner 120285 CZ.jpg',
            default: '/homepage/banner 120285 EN.jpg',
          },
          alt: {
            en: 'Banner 120285 EN',
            de: 'Banner 120285 EN',
            hu: 'Banner 120285 EN',
            hr: 'Banner 120285 EN',
            sl: 'Banner 120285 EN',
            sk: 'Banner 120285 CZ',
            cs: 'Banner 120285 CZ',
            default: 'Banner 120285 EN',
          },
          title: {
            en: 'Banner 120285 EN',
            de: 'Banner 120285 EN',
            hu: 'Banner 120285 EN',
            hr: 'Banner 120285 EN',
            sl: 'Banner 120285 EN',
            sk: 'Banner 120285 CZ',
            cs: 'Banner 120285 CZ',
            default: 'Banner 120285 EN',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'magneticky-uhelnik-nastavitelny-20-200', id: '6683b50d41ac4d001cc23148' },
            },
          },
        },
        {
          src: {
            en: '/homepage/banner BHZ060 EN.jpg',
            de: '/homepage/banner BHZ060 EN.jpg',
            hu: '/homepage/banner BHZ060 EN.jpg',
            hr: '/homepage/banner BHZ060 EN.jpg',
            sl: '/homepage/banner BHZ060 EN.jpg',
            sk: '/homepage/banner BHZ060 CZ.jpg',
            cs: '/homepage/banner BHZ060 CZ.jpg',
            default: '/homepage/banner BHZ060 EN.jpg',
          },
          alt: {
            en: 'Banner BHZ060 EN',
            de: 'Banner BHZ060 EN',
            hu: 'Banner BHZ060 EN',
            hr: 'Banner BHZ060 EN',
            sl: 'Banner BHZ060 EN',
            sk: 'Banner BHZ060 CZ',
            cs: 'Banner BHZ060 CZ',
            default: 'Banner BHZ060 EN',
          },
          title: {
            en: 'Banner BHZ060 EN',
            de: 'Banner BHZ060 EN',
            hu: 'Banner BHZ060 EN',
            hr: 'Banner BHZ060 EN',
            sl: 'Banner BHZ060 EN',
            sk: 'Banner BHZ060 CZ',
            cs: 'Banner BHZ060 CZ',
            default: 'Banner BHZ060 EN',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
        {
          src: {
            en: '/homepage/banner KRT230 EN.jpg',
            de: '/homepage/banner KRT230 EN.jpg',
            hu: '/homepage/banner KRT230 EN.jpg',
            hr: '/homepage/banner KRT230 EN.jpg',
            sl: '/homepage/banner KRT230 EN.jpg',
            sk: '/homepage/banner KRT230 CZ.jpg',
            cs: '/homepage/banner KRT230 CZ.jpg',
            default: '/homepage/banner KRT230 EN.jpg',
          },
          alt: {
            en: 'Banner KRT230 EN',
            de: 'Banner KRT230 EN',
            hu: 'Banner KRT230 EN',
            hr: 'Banner KRT230 EN',
            sl: 'Banner KRT230 EN',
            sk: 'Banner KRT230 CZ',
            cs: 'Banner KRT230 CZ',
            default: 'Banner KRT230 EN',
          },
          title: {
            en: 'Banner KRT230 EN',
            de: 'Banner KRT230 EN',
            hu: 'Banner KRT230 EN',
            hr: 'Banner KRT230 EN',
            sl: 'Banner KRT230 EN',
            sk: 'Banner KRT230 CZ',
            cs: 'Banner KRT230 CZ',
            default: 'Banner KRT230 EN',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'novinky' } },
          },
        },
      ],
      homepageImageSection: [
        {
          src: {
            en: '/homepage/Banner_09_2024_EN.jpg',
            de: '/homepage/Banner_09_2024_EN.jpg',
            hu: '/homepage/Banner_09_2024_EN.jpg',
            hr: '/homepage/Banner_09_2024_EN.jpg',
            sl: '/homepage/Banner_09_2024_EN.jpg',
            sk: '/homepage/Banner_09_2024_CZ.jpg',
            cs: '/homepage/Banner_09_2024_CZ.jpg',
            default: '/homepage/Banner_09_2024_EN.jpg',
          },
          alt: {
            en: 'Info banner - September actions',
            de: 'Info banner - September actions',
            hu: 'Info banner - September actions',
            hr: 'Info banner - September actions',
            sl: 'Info banner - September actions',
            sk: 'Info banner - akce září',
            cs: 'Info banner - akce září',
            default: 'Info banner - September actions',
          },
          title: {
            en: 'Info banner - September actions',
            de: 'Info banner - September actions',
            hu: 'Info banner - September actions',
            hr: 'Info banner - September actions',
            sl: 'Info banner - September actions',
            sk: 'Info banner - akce září',
            cs: 'Info banner - akce září',
            default: 'Info banner - September actions',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'akce' } },
          },
        },
        {
          src: {
            en: '/homepage/Banner-vyprodej_EN.jpg',
            de: '/homepage/Banner-vyprodej_EN.jpg',
            hu: '/homepage/Banner-vyprodej_EN.jpg',
            hr: '/homepage/Banner-vyprodej_EN.jpg',
            sl: '/homepage/Banner-vyprodej_EN.jpg',
            sk: '/homepage/Banner-vyprodej.jpg',
            cs: '/homepage/Banner-vyprodej.jpg',
            default: '/homepage/Banner-vyprodej_EN.jpg',
          },
          alt: {
            en: 'Info banner - warehouse stock sale',
            de: 'Info banner - warehouse stock sale',
            hu: 'Info banner - warehouse stock sale',
            sl: 'Info banner - warehouse stock sale',
            hr: 'Info banner - warehouse stock sale',
            sk: 'Info banner - výprodej skladových zásob',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          title: {
            en: 'Info banner - warehouse stock sale',
            de: 'Info banner - warehouse stock sale',
            hu: 'Info banner - warehouse stock sale',
            hr: 'Info banner - warehouse stock sale',
            sl: 'Info banner - warehouse stock sale',
            sk: 'Info banner - výprodej skladových zásob',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'vyprodej' } },
          },
        },
      ],
    },
    staticContentTypes: [
      'homepage-main-info-box',
      'homepage-secondary-info-box',
      'business-conditions',
      'gdpr',
      'footer-contact-box',
      'footer-headquarters',
    ],
  },
};

const getModuleConfig = (projectName: string) => allConfigs[projectName] || {};

if (!process.env.RAZZLE_PROJECT_NAME) {
  console.error('PROJECT_NAME is not defined');
}

export const knownProjects = Object.keys(allConfigs);

const getProjectName = (name: string | undefined) => name || 'default';

if (!knownProjects.includes(getProjectName(process.env.RAZZLE_PROJECT_NAME))) {
  console.error(
    `PROJECT_NAME "${getProjectName(process.env.RAZZLE_PROJECT_NAME)}" is not known. Known projects are:`,
    knownProjects.join(', '),
  );
}

const getConfig = (projectName: string): Config => ({
  projectName: projectName,
  projectNameUpperCase: projectName.toUpperCase(),
  rumApiKey: process.env.RAZZLE_RUM_API_KEY,
  currency: process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK',
  locale: (process.env.RAZZLE_SX_LOCALE as SupportedLocale) || 'cs',
  region: process.env.RAZZLE_SX_REGION || '_default',
  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'anonymouse',
  ...getModuleConfig(projectName),
});

export const {
  // region: old application settings
  supportedCurrencies,
  supportedLocales,
  pricesType,
  currency,
  locale,
  region,
  anonymouseUserId,

  // region: newly added settings
  projectName,
  projectNameUpperCase,
  customNavigationLinks,
  showExternalDocumentsDetail,
  rumApiKey,
  files,
  multipleFiles,
  monitorStockAvailability,
  productDetailAuthenticatedParameterCodes,
  productDetailGlobalParameters,
  showStockAvailability,
  showContactPage,
  showProductDetailSpagettyNavigation,
  showTermsAndConditions,
  showDownloadDocuments,
  themeOptions,
  staticContentTypes,
  customerProfileNavigationItems,
  activeManufacturersFilter,
} = getConfig(getProjectName(process.env.RAZZLE_PROJECT_NAME));
